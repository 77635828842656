<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 p-2">
        <h2 class="card-title align-items-start pr-0 mr-0">
          <span class="card-label font-weight-bolder text-dark pr-0 mr-0 mx-4 my-4">
            ÖDEV KAYNAK RAPORU
          </span>
        </h2>
        <div class="card-toolbar">
          <v-dialog persistent v-model="filterDialog" max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn small class="mx-2" v-bind="attrs" v-on="on"> FİLTRE </v-btn>
            </template>
            <v-card>
              <v-toolbar flat>
                <v-row class="justify-content-between">
                  <v-col class="col-4">
                    <v-icon @click="filterDialog = false">mdi-close</v-icon>
                  </v-col>
                  <v-col class="col-4 d-flex justify-content-center"> <h5>FİLTRELE</h5> </v-col>
                  <v-col class="col-4 text-right">
                    <v-icon @click="cleanFilter">mdi-refresh</v-icon>
                  </v-col>
                </v-row>
              </v-toolbar>
              <v-card-text>
                <v-autocomplete
                  v-model="lessonIds"
                  :item-text="(item) => item.Name"
                  :item-value="(item) => item.Id"
                  :items="lessons"
                  label="Ders"
                  single-line
                  hide-details
                  clearable
                  multiple
                >
                </v-autocomplete>
                <!-- <v-autocomplete
                  v-model="hwAttendanceStatusId"
                  :item-text="(item) => item.Name"
                  :item-value="(item) => item.Id"
                  :items="hwAttendanceStatuses"
                  label="Ödev Durumu"
                  single-line
                  hide-details
                >
                </v-autocomplete> -->
              </v-card-text>
              <v-card-actions class="justify-content-center">
                <v-btn
                  @click="
                    filterDialog = false
                    getData()
                  "
                >
                  UYGULA
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            ref="dialog"
            v-model="dateDialog"
            :return-value.sync="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="float-right mx-4 my-2" icon v-bind="attrs" v-on="on"
                ><v-icon large> mdi-calendar </v-icon></v-btn
              >
            </template>
            <v-card>
              <v-date-picker
                first-day-of-week="1"
                v-model="dates"
                scrollable
                range
                color="green lighten-1"
                header-color="primary"
                locale="tr"
                min="2021-09-13"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateDialog = false"> İPTAL </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    getData()
                    $refs.dialog.save(dates)
                  "
                >
                  ARA
                </v-btn>
                <v-spacer></v-spacer>
              </v-date-picker>
              <v-radio-group class="mx-3" v-model="isCheckDate" column>
                <v-radio label="Son Kontrol Tarihine Göre" :value="true"></v-radio>
                <v-radio label="Ödev Verilme Tarihine Göre" :value="false"></v-radio>
              </v-radio-group>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="card-body p-2">
        <div class="text-center" v-if="lessonIds.length == 0">Filtreden ders seçiniz.</div>
        <div class="text-center" v-else-if="loading && lessonIds.length > 0">Yükleniyor...</div>
        <div v-else-if="!loading && lessonIds.length > 0">
          <div
            v-if="
              lessonIds.length > 0 || (this.dates[0] && this.dates[1]) || hwAttendanceStatusId > 0
            "
            class="container"
          >
            <h4>
              Seçili Filtreler:
              <span
                class="mx-1 label label-lg label-light-primary label-inline"
                v-if="lessonIds.length > 0"
                >Ders</span
              >
              <span
                class="mx-1 label label-lg label-light-primary label-inline"
                v-if="this.dates[0] || this.dates[1]"
                >Tarih</span
              >
              <span
                class="mx-1 label label-lg label-light-primary label-inline"
                v-if="hwAttendanceStatusId > 0"
                >Ödev Durumu</span
              >
            </h4>
          </div>
          <div class="table-responsive" v-for="source in allData" :key="source.key">
            <h4 class="text-center mt-5">{{ source.key }}</h4>
            <table class="table table-bordered table-vertical-center table-sm rounded">
              <thead class="thead-light">
                <tr>
                  <template v-for="(item, i) in headers">
                    <th class="text-center col-3" v-bind:key="i">{{ item }}</th>
                  </template>
                </tr>
              </thead>
              <tbody>
                <tr v-if="source.values.length <= 0" class="text-center">
                  Ödev Bulunamadı...
                </tr>
                <template v-else v-for="(homework, i) in source.values">
                  <tr v-bind:key="i">
                    <td class="p-2 text-left col-3">
                      {{ homework.SourceChapterName }}
                    </td>
                    <td class="p-2 text-left col-3">
                      {{ homework.SourceTestName }}
                    </td>
                    <td class="p-2 text-center col-3">
                      <b-icon
                        v-if="homework.IsAssigned"
                        icon="check-square-fill"
                        variant="success"
                      ></b-icon>
                    </td>
                    <td class="p-2 text-center col-3">
                      <b-icon
                        v-if="homework.IsCompleted"
                        icon="check-square-fill"
                        variant="success"
                      ></b-icon>
                    </td>
                  </tr>
                </template>
                <!--                <tr>-->
                <!--                  <td class="p-2 text-center col-3"></td>-->
                <!--                  <td class="p-2 text-right col-3">TOPLAM:</td>-->
                <!--                  <td class="p-2 text-center col-3">-->
                <!--                    {{-->
                <!--                      source.values-->
                <!--                        .map((item) => item.CompletedTestCount)-->
                <!--                        .reduce((prev, curr) => prev + curr, 0)-->
                <!--                    }}-->
                <!--                  </td>-->
                <!--                  <td class="p-2 text-center col-3">-->
                <!--                    {{-->
                <!--                      source.values-->
                <!--                        .map((item) => item.NotCompletedTestCount)-->
                <!--                        .reduce((prev, curr) => prev + curr, 0)-->
                <!--                    }}-->
                <!--                  </td>-->
                <!--                  <td class="p-2 text-center col-3">-->
                <!--                    {{-->
                <!--                      source.values-->
                <!--                        .map((item) => item.TestCount)-->
                <!--                        .reduce((prev, curr) => prev + curr, 0)-->
                <!--                    }}-->
                <!--                  </td>-->
                <!--                </tr>-->
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <div class="text-center mt-5">
            <div class="spinner-border"></div>
          </div>
        </div>
        <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor" rounded="pill">
          {{ snackbarText }}
        </v-snackbar>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'odev-kaynak-raporu',
  data() {
    return {
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: '',
      timeout: 2000,
      allData: [],
      loading: true,
      headers: ['Bölüm', 'Test', 'Ödev Verildi Mi', 'Ödev Yapıldı Mı'],
      dateDialog: false,
      dates: [null, null],
      isCheckDate: true,
      filterDialog: false,
      lessons: [],
      lessonIds: [],
      hwAttendanceStatusId: 0,
      hwAttendanceStatuses: [
        { Id: 0, Name: 'Tüm Ödevler' },
        { Id: 1, Name: 'Yapılanlar' },
        { Id: 2, Name: 'Yapılmayanlar' }
      ]
    }
  },
  mounted() {
    this.getLessonsFromApi()
  },
  methods: {
    getData() {
      if (this.lessonIds.length == 0) {
        this.snackbarText = 'Filtrede ders seçiniz'
        this.snackbarColor = 'red'
        this.snackbar = true
        return
      }
      this.loading = true
      if (this.dates[0] && this.dates[1]) {
        var g1 = new Date(this.dates[0])
        var g2 = new Date(this.dates[1])
        if (g2.getTime() < g1.getTime()) {
          var tmpDate = this.dates[0]
          this.dates[0] = this.dates[1]
          this.dates[1] = tmpDate
        }
      }
      var model = {
        StartDate: this.dates[0],
        EndDate: this.dates[1],
        LessonIds: this.lessonIds
      }
      ApiService.setHeader()
      ApiService.post('api/Homework/reports/StudentHomeworkSourceReport', model)
        .then((data) => {
          this.allData = this.groupByArray(data.data, 'SourceName')
          this.loading = false
          this.filterDialog = false
        })
        .catch(({ response }) => {
          this.snackbarText = response.data.SummaryMessage
        })
    },
    groupByArray(xs, key) {
      return xs.reduce(function (rv, x) {
        let v = key instanceof Function ? key(x) : x[key]
        let el = rv.find((r) => r && r.key === v)
        if (el) {
          el.values.push(x)
        } else {
          rv.push({ key: v, values: [x] })
        }
        return rv
      }, [])
    },

    sortByKey(array, key) {
      return array.sort(function (a, b) {
        var x = a[key]
        var y = b[key]
        return x < y ? -1 : x > y ? 1 : 0
      })
    },

    getLessonsFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Lesson', {
        sortBy: ['Name'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: { IsStudent: true }
      })
        .then((data) => {
          this.lessons = data.data.Results
          this.lessonIds = this.lessons.map((item) => item.Id)
          this.getData()
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },

    cleanFilter() {
      this.lessonIds = []
      this.lessonUnitIds = []
      this.subjectIds = []
      this.childSubjectIds = []
    }
  }
}
</script>
